import React from 'react'

import { css } from '@emotion/css'

/**
 */
export const UploadError = ({ children }) => {
  return (
    <p
      className={css`
        color: red;
        padding: 10px;
        margin-left: 20px;
      `}
    >
      {children}
    </p>
  )
}
