import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Cancel = translated('app')('ProjectFileDeleteModal.Cancel')

/**
 */
export const Accept = translated('app')('ProjectFileDeleteModal.Accept')

/**
 */
export const ConfirmMessage = translated('app')(
  'ProjectFileDeleteModal.ConfirmMessage',
)

/**
 */
export const Progress = translated('common')('Progress')

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation('app')

  const formatActionError = (key, ...options) => {
    return t(`ProjectFileDeleteModal.${key}`, ...options)
  }

  return {
    formatActionError,
  }
}
