import React from 'react'

import { css } from '@emotion/css'
import * as Icons from '@mui/icons-material'

/**
 */
export const UploadButton = ({ onUpload, children }) => {
  return (
    <label>
      <input
        type="file"
        onChange={(e) => {
          onUpload(e)
          e.target.value = ''
        }}
        className={css`
          display: none;
        `}
        multiple
      />
      <span
        className={css`
          align-items: center;
          background-color: #e51102;
          border: 1px solid #e51102;
          color: #fff;
          cursor: pointer;
          display: inline-flex;
          font-size: 0.9rem;
          font-weight: 700;
          justify-content: center;
          margin: 0 2px;
          min-height: 40px;
          padding: 2px 7px;
          pointer-events: all;
          position: relative;
          width: min(100%, 350px);
        `}
      >
        {children}
      </span>
    </label>
  )
}

/**
 */
export const DragAndDropDescription = ({ children }) => {
  return <span className={css``}>{children}</span>
}

/**
 */
export const DragAndDropArea = React.forwardRef(
  ({ children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={css`
          display: contents;
        `}
        {...props}
      >
        {children}
      </div>
    )
  },
)

export const DropMessage = ({ children }) => {
  return (
    <div
      className={css`
        height: 100%;
        width: 100%;
        z-index: 100;
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <Icons.UploadFile
        className={css`
          width: 300px;
          height: 300px;
          color: #333;
        `}
      />
      <p>{children}</p>
    </div>
  )
}

/**
 */
export const UploadError = ({ children }) => {
  return (
    <p
      className={css`
        color: red;
        padding: 10px;
        margin-left: 20px;
      `}
    >
      {children}
    </p>
  )
}
