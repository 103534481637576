import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Upload = translated('app.project')('FileStoragePage.Upload')

/**
 */
export const DragAndDropDescription = translated('app.project')(
  'FileStoragePage.DragAndDropDescription',
)

/**
 */
export const DropMessage = translated('app.project')(
  'FileStoragePage.DropMessage',
)

/**
 */
export const Uploading = translated('app.project')('FileStoragePage.Uploading')
