import React from 'react'

import * as Modal from '@arch-log/webapp.atomics/components/Modal'
import * as Content from './Content'

/**
 */
export const useActions = () => {
  const { show, hide } = React.useContext(Modal.Context)

  const showModal = ({
    projectId,
    projectUuid,
    projectFileId,
    setSelected,
  }) => {
    show(() => (
      <Content.Flow
        projectId={projectId}
        projectUuid={projectUuid}
        projectFileId={projectFileId}
        onComplete={() => {
          setSelected(null)

          hide()
        }}
        onCancel={() => hide()}
      />
    ))
  }

  return {
    showModal,
    closeModal: hide,
  }
}
