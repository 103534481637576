import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const FileNameLabel = translated('app.project')(
  'FileStoragePage.FileNameLabel',
)

/**
 */
export const CreatedAtLabel = translated('app.project')(
  'FileStoragePage.CreatedAtLabel',
)

/**
 */
export const UpdatedAtLabel = translated('app.project')(
  'FileStoragePage.UpdatedAtLabel',
)

/**
 */
export const DownloadLink = translated('app.project')(
  'FileStoragePage.DownloadLink',
)

/**
 */
export const Delete = translated('app.project')('FileStoragePage.Delete')
