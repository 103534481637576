import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Message = ({ children, ...props }) => (
  <div {...props} className={styles.Message}>
    {children}
  </div>
)

/**
 */
export const CancelButton = ({ onClick, children }) => (
  <button type="button" className={styles.CancelButton} onClick={onClick}>
    {children}
  </button>
)

/**
 */
export const AcceptButton = ({ onClick, children }) => (
  <button type="button" onClick={onClick} className={styles.AcceptButton}>
    {children}
  </button>
)

/**
 */
export const ActionError = ({ children, ...props }) => (
  <p {...props} className={styles.ActionError}>
    {children}
  </p>
)
