import React from 'react'

import * as styles from './layout.module.scss'

/**
 */
export const Wrapper = ({ children }) => {
  return <div className={styles.Wrapper}>{children}</div>
}

/**
 */
export const Header = ({ children }) => {
  return <div className={styles.Header}>{children}</div>
}

/**
 */
export const Body = ({ children }) => {
  return <div className={styles.Body}>{children}</div>
}

/**
 */
export const EntriesWrapper = ({ children }) => {
  return <div className={styles.EntriesWrapper}>{children}</div>
}
