import React from 'react'

import { Loader } from '@arch-log/webapp.atomics/components/Loader'

import * as Literals from './Literals'
import * as Styled from './Styled'

/**
 */
export const ConfirmMessage = ({
  componenst: { Decorator = Styled.Message } = {},
}) => (
  <Decorator>
    <Literals.ConfirmMessage />
  </Decorator>
)

/**
 */
export const Cancel = ({ onCancel }) => (
  <Styled.CancelButton onClick={onCancel}>
    <Literals.Cancel />
  </Styled.CancelButton>
)

/**
 */
export const Accept = ({ onClick }) => (
  <Styled.AcceptButton onClick={onClick}>
    <Literals.Accept />
  </Styled.AcceptButton>
)

/**
 */
export const ActionError = ({ error }) => {
  const { formatActionError } = Literals.useLiterals()

  if (!error) {
    return null
  }

  return <Styled.ActionError>{formatActionError(error)}</Styled.ActionError>
}

/**
 */
export const ProgressBar = () => {
  return (
    <Loader>
      <Literals.Progress />
    </Loader>
  )
}
