import React from 'react'

import { FileImage as BaseFileImage } from 'components/FileImage'

import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as Permissions from '@arch-log/webapp.modules/project.authorization.v2/permissions'
import * as ProjectFile from '@arch-log/webapp.modules/project.file/components'
import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as Icons from '@arch-log/webapp.shared/Icons'
import * as DeleteModal from './DeleteModal'
import * as Selected from './Selected'
import * as Styled from './Styeld'
import * as Literals from './Literals'

import * as styles from './styles.module.scss'

/**
 */
export const FileImage = () => {
  const { entry } = ProjectFile.Scope.Entry.useEntry()

  return (
    <BaseFileImage
      className={styles.FileImage}
      mimeType={entry?.mimeType}
      extname={entry?.extname}
      alt={entry?.label}
    />
  )
}

/**
 */
export const Close = ({ onClose }) => (
  <Styled.CloseButton onClose={() => onClose()} />
)

/**
 */
export const FileName = () => {
  return (
    <Styled.FileName>
      <ProjectFile.Scope.Entry.Properties.FileName />
    </Styled.FileName>
  )
}

/**
 */
export const FileNameLabel = () => {
  return (
    <Styled.FileNameLabel>
      <Literals.FileNameLabel />
    </Styled.FileNameLabel>
  )
}

/**
 */
export const CreatedAt = () => {
  return (
    <Styled.CreatedAt>
      <ProjectFile.Scope.Entry.Properties.CreatedAt />
    </Styled.CreatedAt>
  )
}

/**
 */
export const CreatedAtLabel = () => {
  return (
    <Styled.CreatedAtLabel>
      <Literals.CreatedAtLabel />
    </Styled.CreatedAtLabel>
  )
}

/**
 */
export const UpdatedAt = () => {
  return (
    <Styled.UpdatedAt>
      <ProjectFile.Scope.Entry.Properties.UpdatedAt />
    </Styled.UpdatedAt>
  )
}

/**
 */
export const UpdatedAtLabel = () => {
  return (
    <Styled.UpdatedAtLabel>
      <Literals.UpdatedAtLabel />
    </Styled.UpdatedAtLabel>
  )
}

/**
 */
export const Download = () => {
  const { entry } = ProjectFile.Scope.Entry.useEntry()

  return (
    <Styled.DownloadLink url={entry?.publicUrl} target={'_blank'}>
      <Icons.FileDownloadIcon />
      <Literals.DownloadLink />
    </Styled.DownloadLink>
  )
}

/**
 */
export const Delete = () => {
  const hasPermission = ProjectAuthorization.Remote.Queries.MyEntry.useHasPermissions()

  const { entry: projectFileEntry } = ProjectFile.Scope.Entry.useEntry()
  const [projectEntry] = Project.Scope.Entry.useEntry()
  const { setSelected } = React.useContext(Selected.Context)
  const { showModal } = DeleteModal.useActions()

  if (!hasPermission(Permissions.FileDelete)) {
    return null
  }

  return (
    <Styled.Delete
      onConfirm={() =>
        showModal({
          projectId: projectEntry?.id,
          projectUuid: projectEntry?.uuid,
          projectFileId: projectFileEntry?.id,
          setSelected,
        })
      }
    >
      <Icons.DeleteIcon />
    </Styled.Delete>
  )
}
