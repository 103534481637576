import { useTranslation } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation('app.project')

  const formatUploadError = (key, ...options) => {
    return t(`FileStoragePage.Errors.Upload.${key}`, ...options)
  }

  return {
    formatUploadError,
  }
}
