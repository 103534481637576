import React from 'react'

import * as Routes from '@arch-log/webapp.shared/AppDefinitions/Project/Routes'
import * as ProjectFile from '@arch-log/webapp.modules/project.file/components'
import * as Containers from './Containers'
import * as Layout from './Layout'

import { createFlow } from '@arch-log/webapp.atomics/components/Flow'
import { useAsync } from 'react-use'

/**
 */
export const Init = ({ next }) => {
  React.useEffect(() => {
    next()
  }, [])

  return null
}

/**
 */
export const Confirm = ({ context, next, onCancel }) => {
  return (
    <Layout.Container>
      <Layout.Body>
        <Containers.ConfirmMessage />
        <Containers.ActionError error={context?.actionError ?? null} />
      </Layout.Body>
      <Layout.Footer>
        <Containers.Accept onClick={() => next()} />
        <Containers.Cancel onCancel={() => onCancel()} />
      </Layout.Footer>
    </Layout.Container>
  )
}

/**
 */
const ProgressStep = ({ projectUuid, projectFileId, next, prev }) => {
  const [deleteEntry] = ProjectFile.Remote.Mutations.DeleteEntry.useMutation()

  useAsync(async () => {
    try {
      await deleteEntry({
        variables: {
          projectId: projectUuid,
          id: projectFileId,
        },
        onCompleted: (data) => {
          next()
        },
        onError: (error) => {
          prev({
            actionError: 'Actions.DeleteError.Failed',
          })
        },
      })
    } catch (err) {
      prev({
        actionError: 'Actions.DeleteError.Failed',
      })
    }
  }, [])

  return <Containers.ProgressBar />
}

/**
 */
const RefetchStep = ({ projectId, next }) => {
  const { refetch } = ProjectFile.Remote.Queries.Entries.useContext()

  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    if (error) {
      window.location.href = Routes.toFileStorage({
        project: projectId,
      })
    }
  }, [error])

  useAsync(async () => {
    try {
      await refetch()
      next()
    } catch (error) {
      setError(error)
    }
  }, [])

  return <Containers.ProgressBar />
}

/**
 */
const BaseSubFlow = createFlow([ProgressStep, RefetchStep])

/**
 */
export const Progress = ({
  next,
  prev,
  projectId,
  projectUuid,
  projectFileId,
}) => {
  return (
    <Layout.Container>
      <Layout.Body>
        <BaseSubFlow
          projectId={projectId}
          projectUuid={projectUuid}
          projectFileId={projectFileId}
          onComplete={() => {
            next()
          }}
          prev={prev}
        />
      </Layout.Body>
    </Layout.Container>
  )
}

/**
 */
export const Complete = ({ onComplete }) => {
  React.useEffect(() => {
    onComplete()
  }, [])

  return null
}
