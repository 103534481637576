import React from 'react'

import { FileImage as BaseFileImage } from 'components/FileImage'

import * as ProjectFile from '@arch-log/webapp.modules/project.file/components'
import * as Styled from './Styled'

import * as styles from './styles.module.scss'

/**
 */
export const FileImage = ({ draggable = false }) => {
  const { entry } = ProjectFile.Scope.Entry.useEntry()

  return (
    <BaseFileImage
      draggable={draggable}
      className={styles.FileImage}
      mimeType={entry?.mimeType}
      extname={entry?.extname}
      alt={entry?.label}
    />
  )
}

/**
 */
export const FileName = () => {
  return (
    <Styled.FileName>
      <ProjectFile.Scope.Entry.Properties.FileName />
    </Styled.FileName>
  )
}
