import React from 'react'

import * as styles from './layout.module.scss'

/**
 */
export const Wrapper = ({ children }) => {
  return <div className={styles.Wrapper}>{children}</div>
}

/**
 */
export const Header = ({ children }) => {
  return <>{children}</>
}

/**
 */
export const Body = ({ children }) => {
  return <div className={styles.Body}>{children}</div>
}

/**
 */
export const Footer = ({ children }) => {
  return <div className={styles.Footer}>{children}</div>
}

/**
 */
export const FooterContents = ({ children }) => {
  return <div className={styles.FooterContents}>{children}</div>
}

/**
 */
export const ListSection = ({ children }) => {
  return (
    <div className={styles.ListSection}>
      <div className={styles.ListSection_Inner}>{children}</div>
    </div>
  )
}

/**
 */
export const ListSectionBody = ({ children }) => {
  return <div className={styles.ListSection_Body}>{children}</div>
}

/**
 */
export const ListSectionFooter = ({ children }) => {
  return <div className={styles.ListSection_Footer}>{children}</div>
}

/**
 */
export const DetailSection = ({ children }) => {
  return <div className={styles.DetailSection}>{children}</div>
}
