import React from 'react'

import * as styles from './latout.module.scss'

/**
 */
export const Wrapper = ({ children }) => <>{children}</>

/**
 */
export const ContentWrapper = ({ children }) => (
  <div className={styles.Layout_ContentWrapper}>{children}</div>
)

/**
 */
export const ContentImage = ({ children }) => (
  <div className={styles.Layout_ContentImageWrapper}>
    <div className={styles.Layout_ContentImage}>{children}</div>
  </div>
)

/**
 */
export const ContentInfo = ({ children }) => (
  <div className={styles.Layout_ContentInfo}>{children}</div>
)

/**
 */
export const ContentInfoRow = ({ children }) => {
  return <div className={styles.Layout_ContentInfoRow}>{children}</div>
}

/**
 */
export const ContentInfoRowHead = ({ children }) => {
  return <div className={styles.Layout_ContentInfoRow_Head}>{children}</div>
}

/**
 */
export const ContentInfoRowCell = ({ children }) => {
  return <div className={styles.Layout_ContentInfoRow_Cell}>{children}</div>
}

/**
 */
export const DownloadAndDelete = ({ children }) => {
  return <div className={styles.Layout_DownloadAndDelete}>{children}</div>
}

/**
 */
export const Download = ({ children }) => {
  return <>{children}</>
}

/**
 */
export const Delete = ({ children }) => {
  return <>{children}</>
}
