import React from 'react'

import * as ProjectFile from '@arch-log/webapp.modules/project.file/components'
import { Context } from './Context'

/**
 */
export const IfSelected = ({ children }) => {
  const { selected } = React.useContext(Context)

  if (!selected) {
    return null
  }

  return (
    <ProjectFile.Scope.Entry.Provider entry={selected}>
      {children}
    </ProjectFile.Scope.Entry.Provider>
  )
}
