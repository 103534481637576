import React from 'react'

import * as Layout from './Layout'
import * as Containers from './Containers'

/**
 */
export const Default = () => {
  return (
    <Layout.Wrapper>
      <Layout.Header>
        <Containers.UploadError />
      </Layout.Header>
      <Layout.Body>
        <Layout.EntriesWrapper>
          <Containers.Entries>
            <Containers.Entry />
          </Containers.Entries>
        </Layout.EntriesWrapper>
      </Layout.Body>
    </Layout.Wrapper>
  )
}
