import React from 'react'

import * as ProjectFile from '@arch-log/webapp.modules/project.file/components'
import * as BaseGridViewEntry from './GridView/Entry'
import * as Selected from './Selected'
import * as Literals from './Literals'
import * as Styled from './Styled'

/**
 */
export const Entries = ({ children }) => {
  return (
    <ProjectFile.Scope.Entries.Each>{children}</ProjectFile.Scope.Entries.Each>
  )
}

/**
 */
export const Entry = ({ mode = 'grid' }) => {
  const { entry } = ProjectFile.Scope.Entry.useEntry()

  const { selected, setSelected } = React.useContext(Selected.Context)

  const props = {
    isSelected: selected && selected.id === entry.id,
    onSelect: () => {
      if (selected && selected.id === entry.id) {
        setSelected(null)
      } else {
        setSelected(entry)
      }
    },
  }

  // mode change switching entry view
  switch (mode) {
    default:
      return <BaseGridViewEntry.Default {...props} />
  }
}

/**
 */
export const UploadError = () => {
  const { formatUploadError } = Literals.useLiterals()
  const [_, { error }] = ProjectFile.Remote.Upload.useUpload()

  if (!error) {
    return null
  }

  return <Styled.UploadError>{formatUploadError(error)}</Styled.UploadError>
}
