import React from 'react'

import { Page as PageLoader } from '@arch-log/webapp.atomics/components/Loader'
import { useDropzone } from 'react-dropzone'

import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as Permissions from '@arch-log/webapp.modules/project.authorization.v2/permissions'
import * as FileUploadingOverlay from 'components/FileUploadingOverlay'
import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as ProjectFile from '@arch-log/webapp.modules/project.file/components'
import * as BaseProjectFileList from 'components/ProjectFileList'
import * as BaseProjectFileDetail from 'components/ProjectFileDetail'
import * as Styled from './Styled'
import * as Literals from './Literals'

/**
 */
export const Initializer = ({ children }) => {
  const [projectEntry] = Project.Scope.Entry.useEntry()

  return (
    <ProjectFile.Remote.Queries.Entries.Loader
      projectId={projectEntry?.uuid}
      components={{ Loading: () => <PageLoader>loading</PageLoader> }}
    >
      <ProjectFile.Remote.Upload.Initializer
        throwError={false}
        projectId={projectEntry?.uuid}
      >
        <FileUploadingOverlay.Uploading />
        <UploadedPropagation />
        <BaseProjectFileList.Selected.Initializer>
          <BaseProjectFileDetail.Selected.Initializer>
            <SelectedPropagation />
            {children}
          </BaseProjectFileDetail.Selected.Initializer>
        </BaseProjectFileList.Selected.Initializer>
      </ProjectFile.Remote.Upload.Initializer>
    </ProjectFile.Remote.Queries.Entries.Loader>
  )
}

/**
 */
const UploadedPropagation = () => {
  const [_, { uploaded }] = ProjectFile.Remote.Upload.useUpload()
  const { refetch } = ProjectFile.Remote.Queries.Entries.useContext()

  React.useEffect(() => {
    if (uploaded) {
      refetch()
    }
  }, [uploaded])

  return null
}

/**
 */
export const FileDropZone = ({ children }) => {
  const hasPermission = ProjectAuthorization.Remote.Queries.MyEntry.useHasPermissions()
  const [upload] = ProjectFile.Remote.Upload.useUpload()

  const onUpload = React.useCallback((files) => {
    if (files.length > 0) {
      upload(files)
    }
  }, [])

  const { getRootProps, isDragActive } = useDropzone({
    onDrop: onUpload,
    noClick: true,
  })

  const OnDragMessage = isDragActive ? DropMessage : React.Fragment

  if (!hasPermission(Permissions.FileWrite)) {
    return <>{children}</>
  }

  return (
    <Styled.DragAndDropArea {...getRootProps()}>
      <OnDragMessage />
      {children}
    </Styled.DragAndDropArea>
  )
}

const DropMessage = () => (
  <Styled.DropMessage>
    <Literals.DropMessage />
  </Styled.DropMessage>
)

/**
 */
const SelectedPropagation = () => {
  const { selected } = React.useContext(BaseProjectFileList.Selected.Context)
  const { setSelected } = React.useContext(
    BaseProjectFileDetail.Selected.Context,
  )

  React.useEffect(() => {
    setSelected(selected)
  }, [selected])

  return null
}

/**
 */
export const ProjectFileList = BaseProjectFileList.Default

/**
 */
export const ProjectFileDetail = () => {
  const { setSelected } = React.useContext(BaseProjectFileList.Selected.Context)

  return (
    <BaseProjectFileDetail.Default
      onClose={() => {
        setSelected(null)
      }}
    />
  )
}

/**
 */
export const Upload = () => {
  const hasPermission = ProjectAuthorization.Remote.Queries.MyEntry.useHasPermissions()
  const [upload] = ProjectFile.Remote.Upload.useUpload()

  const handleUpload = (e) => {
    upload(e.target.files)
  }

  if (!hasPermission(Permissions.FileWrite)) {
    return null
  }

  return (
    <Styled.UploadButton onUpload={handleUpload}>
      <Literals.Upload />
    </Styled.UploadButton>
  )
}

/**
 */
export const DragAndDropDescription = () => {
  const hasPermission = ProjectAuthorization.Remote.Queries.MyEntry.useHasPermissions()

  if (!hasPermission(Permissions.FileWrite)) {
    return null
  }

  return (
    <Styled.DragAndDropDescription>
      <Literals.DragAndDropDescription />
    </Styled.DragAndDropDescription>
  )
}
