import React from 'react'

import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const Default = ({ isSelected, onSelect }) => {
  return (
    <Layout.Wrapper isSelected={isSelected} onSelect={onSelect}>
      <Containers.FileImage />
      <Containers.FileName />
    </Layout.Wrapper>
  )
}
