import React from 'react'

import * as ProjectFileDetail from 'components/ProjectFileDetail'
import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as Permissions from '@arch-log/webapp.modules/project.authorization.v2/permissions'
import * as NoPermission from '@arch-log/webapp.shared/Errors/NoPermission'
import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const Default = () => {
  const hasPermission = ProjectAuthorization.Remote.Queries.MyEntry.useHasPermissions()

  if (!hasPermission(Permissions.FileRead)) {
    return <NoPermission.Default />
  }

  return (
    <Containers.Initializer>
      <Layout.Wrapper>
        <Containers.FileDropZone>
          <Layout.ListSection>
            <Layout.ListSectionBody>
              <Containers.ProjectFileList />
            </Layout.ListSectionBody>
            <Layout.ListSectionFooter>
              <Containers.Upload />
              <Containers.DragAndDropDescription />
            </Layout.ListSectionFooter>
          </Layout.ListSection>
        </Containers.FileDropZone>

        <ProjectFileDetail.Selected.IfSelected>
          <Layout.DetailSection>
            <Containers.ProjectFileDetail />
          </Layout.DetailSection>
        </ProjectFileDetail.Selected.IfSelected>
      </Layout.Wrapper>
    </Containers.Initializer>
  )
}
