import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Wrapper = ({ isSelected, onSelect, children }) => {
  return (
    <div
      className={isSelected ? styles.Wrapper_Selected : styles.Wrapper}
      onClick={() => onSelect()}
    >
      {children}
    </div>
  )
}
