import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'
import * as styles from './styles.module.scss'

/**
 */
export const CloseButton = ({ onClose }) => {
  return (
    <div className={styles.CloseButton} onClick={() => onClose()}>
      <div className={styles.CloseButton_Icon}>
        <Icons.ModalClose />
      </div>
    </div>
  )
}

/**
 */
export const FileName = ({ children }) => {
  return <>{children}</>
}

/**
 */
export const FileNameLabel = ({ children }) => {
  return <>{children}</>
}

/**
 */
export const CreatedAt = ({ children }) => {
  return <>{children}</>
}

/**
 */
export const CreatedAtLabel = ({ children }) => {
  return <>{children}</>
}

/**
 */
export const UpdatedAt = ({ children }) => {
  return <>{children}</>
}

/**
 */
export const UpdatedAtLabel = ({ children }) => {
  return <>{children}</>
}

/**
 */
export const DownloadLink = ({ url, alt = '', children, ...props }) => {
  return (
    <a className={styles.DownloadLink} {...props} href={url} alt={alt}>
      {children}
    </a>
  )
}

/**
 */
export const Delete = ({ onConfirm, children, ...props }) => {
  return (
    <span className={styles.Delete} {...props} onClick={onConfirm}>
      {children}
    </span>
  )
}
