import React from 'react'

import * as ProjectFile from '@arch-log/webapp.modules/project.file/components'
import * as Steps from './Steps'

import { createFlow } from '@arch-log/webapp.atomics/components/Flow'

/**
 */
const BaseFlow = createFlow([
  Steps.Init,
  Steps.Confirm,
  Steps.Progress,
  Steps.Complete,
])

/**
 */
export const Flow = ({ projectUuid, ...props }) => {
  return (
    <ProjectFile.Remote.Queries.Entries.Loader projectId={projectUuid}>
      <ProjectFile.Remote.Mutations.DeleteEntry.Initializer>
        <BaseFlow projectUuid={projectUuid} {...props} />
      </ProjectFile.Remote.Mutations.DeleteEntry.Initializer>
    </ProjectFile.Remote.Queries.Entries.Loader>
  )
}
