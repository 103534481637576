import React from 'react'

import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const Default = ({ onClose }) => {
  return (
    <Layout.Wrapper>
      <Containers.Close onClose={() => onClose()} />
      <Layout.ContentWrapper>
        <Layout.ContentImage>
          <Containers.FileImage />
        </Layout.ContentImage>
        <Layout.ContentInfo>
          {/*---------------------*/}
          <Layout.ContentInfoRow>
            <Layout.ContentInfoRowHead>
              <Containers.FileNameLabel />
            </Layout.ContentInfoRowHead>
            <Layout.ContentInfoRowCell>
              <Containers.FileName />
            </Layout.ContentInfoRowCell>
          </Layout.ContentInfoRow>

          <Layout.ContentInfoRow>
            <Layout.ContentInfoRowHead>
              <Containers.CreatedAtLabel />
            </Layout.ContentInfoRowHead>
            <Layout.ContentInfoRowCell>
              <Containers.CreatedAt />
            </Layout.ContentInfoRowCell>
          </Layout.ContentInfoRow>

          <Layout.ContentInfoRow>
            <Layout.ContentInfoRowHead>
              <Containers.UpdatedAtLabel />
            </Layout.ContentInfoRowHead>
            <Layout.ContentInfoRowCell>
              <Containers.UpdatedAt />
            </Layout.ContentInfoRowCell>
          </Layout.ContentInfoRow>
        </Layout.ContentInfo>

        <Layout.DownloadAndDelete>
          <Containers.Download />
          <Containers.Delete />
        </Layout.DownloadAndDelete>
      </Layout.ContentWrapper>
    </Layout.Wrapper>
  )
}
