import React from 'react'

import { useNavigate } from '@arch-log/webapp.lib/react.hook'
import { Default as FeatureValidation } from 'components/FeatureValidation'

import * as PageHeadTemplates from '@arch-log/webapp.shared/Page/Head'
import * as config from '@arch-log/webapp.lib/config'
import * as Partial from 'partials/FileStoragePage'

/**
 */
export const Head = ({ pageContext }) => {
  return <PageHeadTemplates.Language language={pageContext?.language} />
}

/**
 */
const Page = ({ id: projectId }) => {
  const { redirectTo } = useNavigate()

  if (!config.get(`features.projectFileStorage`, false)) {
    redirectTo('/404')

    return null
  }

  return (
    <FeatureValidation onDeactive={() => redirectTo('/404')}>
      <Partial.Default projectId={projectId} />
    </FeatureValidation>
  )
}

export default Page
